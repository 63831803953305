import React, { useEffect, useState } from 'react';
import { CartesianGrid, BarChart, Bar, Tooltip, XAxis, YAxis } from 'recharts';
import { Payload, ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import { Segment } from 'semantic-ui-react'
import { DailyStats } from 'types/api'
import { getDateString } from 'utils/getDateString';

interface MoodChartProps {
    data: DailyStats[];
}

const mapMoodToNum = (mood: string | null | undefined) => {
    switch (mood) {
        case 'very_good':
            return 2;
        case 'good':
            return 1;
        case 'bad':
            return -1;
        case 'very_bad':
            return -2;
        case 'average':
        default:
            return 0;
    }
}

const mapNumToMood = (num?: number) => {
    switch (num) {
        case 2:
            return '😀 Very good';
        case 1:
            return '🙂 Good';
        case -1:
            return '🙁 Bad';
        case -2:
            return '☹️ Very bad';
        case 0:
        default:
            return '😐 Average';
    }
}

const CustomTooltip = ({ active, payload, label }: { active?: boolean, payload?: Payload<ValueType, NameType>[], label?: string | number }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ padding: '10px', backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)' }}>
                <p className="label">{`${label} : ${mapNumToMood(payload[0].value as number)}`}</p>
                <p className="desc">{payload[0].payload.notes}</p>
            </div>
        );
    }

    return null;
};

export const MoodChart: React.FunctionComponent<MoodChartProps> = (props) => {
    const { data } = props;
    const [currentData, setCurrentData] = useState<{ name: string, value: number, notes?: string }[]>([]);

    useEffect(() => {
        if (data.length <= 0) {
            setCurrentData([]);
            return;
        }

        const dataObjectByDate: { [key: string]: { value: number, notes?: string } } = data.reduce((a, v) => ({ ...a, [v.date]: { value: mapMoodToNum(v.mood), notes: v.notes } }), {});
        const earliest = new Date(data[0].date);
        const latest = new Date(data[data.length - 1].date);

        const newData: { name: string, value: number, notes?: string }[] = [];
        while (earliest.getTime() <= latest.getTime()) {
            const dateKey = getDateString(earliest);
            newData.push({ name: dateKey, ...dataObjectByDate[dateKey] });
            earliest.setDate(earliest.getDate() + 1);
        }

        setCurrentData(newData);
    }, [data]);

    const width = Math.max(window.innerWidth * 0.8, 400);

    return (
        <Segment>
            <BarChart width={width} height={400} data={currentData}>
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="name" hide />
                <YAxis type='number' domain={[-2, 2]} interval="preserveStartEnd" />
                <Tooltip content={({ active, payload, label }) => <CustomTooltip active={active} payload={payload} label={label} />} />
                <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
        </Segment>
    )
};