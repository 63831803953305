/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDailyStats = /* GraphQL */ `
  mutation CreateDailyStats(
    $input: CreateDailyStatsInput!
    $condition: ModelDailyStatsConditionInput
  ) {
    createDailyStats(input: $input, condition: $condition) {
      id
      date
      weightKg
      neckCm
      waistCm
      mood
      notes
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateDailyStats = /* GraphQL */ `
  mutation UpdateDailyStats(
    $input: UpdateDailyStatsInput!
    $condition: ModelDailyStatsConditionInput
  ) {
    updateDailyStats(input: $input, condition: $condition) {
      id
      date
      weightKg
      neckCm
      waistCm
      mood
      notes
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteDailyStats = /* GraphQL */ `
  mutation DeleteDailyStats(
    $input: DeleteDailyStatsInput!
    $condition: ModelDailyStatsConditionInput
  ) {
    deleteDailyStats(input: $input, condition: $condition) {
      id
      date
      weightKg
      neckCm
      waistCm
      mood
      notes
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
