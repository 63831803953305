/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDailyStats = /* GraphQL */ `
  query GetDailyStats($id: ID!) {
    getDailyStats(id: $id) {
      id
      date
      weightKg
      neckCm
      waistCm
      mood
      notes
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listDailyStats = /* GraphQL */ `
  query ListDailyStats(
    $filter: ModelDailyStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        weightKg
        neckCm
        waistCm
        mood
        notes
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
