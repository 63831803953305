import React, { useState, useEffect } from "react";
import { Button, Dimmer, Form, Input, Loader, Segment, Select, TextArea } from 'semantic-ui-react'
import { CreateDailyStatsInput, DailyStats, UpdateDailyStatsInput } from 'types/api';
import { timeout } from "utils/timeout"
import { getDateString } from "utils/getDateString";
import { validateFormData } from 'utils/validateFormData'
import { fields, numberFields, moodOptions } from './constants'

interface EntryFormProps {
    fetchStats: (range?: { from: string, to: string }) => Promise<DailyStats[]>;
    createEntry: (data: CreateDailyStatsInput) => Promise<void>;
    updateEntry: (data: UpdateDailyStatsInput) => Promise<void>;
}

export const EntryForm: React.FunctionComponent<EntryFormProps> = (props) => {
    const { fetchStats, createEntry, updateEntry } = props;
    const [errorFields, setErrorFields] = useState<{ [key: string]: string }>({});
    const [loading, setLoading] = useState<boolean>(false);

    const [id, setId] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [weightKg, setWeightKg] = useState<string>('');
    const [neckCm, setNeckCm] = useState<string>('');
    const [waistCm, setWaistCm] = useState<string>('');
    const [mood, setMood] = useState<string>('');
    const [notes, setNotes] = useState<string>('');

    useEffect(() => {
        const e = { target: { value: getDateString(new Date()) } } as React.ChangeEvent<HTMLInputElement>
        handleDateChange(e);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetForm = () => {
        setId('');
        setDate('');
        setWeightKg('');
        setNeckCm('');
        setWaistCm('');
        setMood('');
        setNotes('');
        setErrorFields({});
    }

    const handleStringValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setFunc: React.Dispatch<React.SetStateAction<string>>) => {
        const value = event.target.value;
        setFunc(value);
    }

    const handleNumberValueChange = (event: React.ChangeEvent<HTMLInputElement>, setFunc: React.Dispatch<React.SetStateAction<string>>) => {
        const value = event.target.value.replace(/[^\d\.]/g, '');
        setFunc(value);
    }

    const handleDateChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const newDate = event.target.value;

        // user clicked the 'x' and wiped the date from the field
        if (!newDate) {
            resetForm();
            return;
        }

        try {
            setLoading(true);
            const stats = (await fetchStats({ from: newDate, to: newDate }))[0];

            if (!!stats) {
                setId(stats.id);
                setDate(stats.date);
                setWeightKg(!!stats.weightKg ? String(stats.weightKg) : '');
                setNeckCm(!!stats.neckCm ? String(stats.neckCm) : '');
                setWaistCm(!!stats.waistCm ? String(stats.waistCm) : '');
                setMood(stats.mood || '');
                setNotes(stats.notes || '');
            } else {
                resetForm();
                setDate(newDate);
            }

            await timeout(500);
            setLoading(false);
        } catch {
            await timeout(500);
            setLoading(false);
        }
    }

    const handleFormSubmit = async () => {
        setLoading(true);

        const data: any = {
            id,
            date,
            weightKg,
            neckCm,
            waistCm,
            mood,
            notes,
        };

        // Remove empty fields
        for (let key in data) {
            if (!data[key]) delete data[key];
        }

        // Convert numbers
        const numberFieldsList = Object.values(numberFields);
        numberFieldsList.forEach(key => {
            if (key in data) {
                const item = Number(data[key]);
                data[key] = item;
            }
        })

        const newErrorFields = validateFormData(data, ['date'], numberFieldsList);
        setErrorFields(newErrorFields);

        if (Object.keys(newErrorFields).length === 0) {
            if (!!data.id) {
                await updateEntry(data as UpdateDailyStatsInput);
            } else {
                await createEntry(data as CreateDailyStatsInput);
            }
            resetForm();
        }

        await timeout(500);
        setLoading(false);
    }

    return (
        <Segment inverted>
            <Dimmer active={loading}>
                {loading && <Loader />}
            </Dimmer>
            <Form onSubmit={handleFormSubmit} inverted>
                <Form.Field required error={!!errorFields[fields.date]}>
                    <label>Date</label>
                    <Input
                        name={fields.date}
                        type={'date'}
                        onChange={handleDateChange}
                        value={date}
                    />
                </Form.Field>
                <Form.Field error={!!errorFields[fields.weightKg]}>
                    <label>Weight</label>
                    <Input
                        name={fields.weightKg}
                        label={{ basic: true, content: 'kg' }}
                        labelPosition='right'
                        placeholder='Enter weight...'
                        onChange={(e) => handleNumberValueChange(e, setWeightKg)}
                        value={weightKg}
                    />
                </Form.Field>
                <Form.Field error={!!errorFields[fields.neckCm]}>
                    <label>Neck</label>
                    <Input
                        name={fields.neckCm}
                        label={{ basic: true, content: 'cm' }}
                        labelPosition='right'
                        placeholder='Enter neck...'
                        onChange={(e) => handleNumberValueChange(e, setNeckCm)}
                        value={neckCm}
                    />
                </Form.Field>
                <Form.Field error={!!errorFields[fields.waistCm]}>
                    <label>Waist</label>
                    <Input
                        name={fields.waistCm}
                        label={{ basic: true, content: 'cm' }}
                        labelPosition='right'
                        placeholder='Enter waist...'
                        onChange={(e) => handleNumberValueChange(e, setWaistCm)}
                        value={waistCm}
                    />
                </Form.Field>
                <Form.Field error={!!errorFields[fields.mood]}>
                    <label>Mood</label>
                    <Select
                        name={fields.mood}
                        placeholder='Select your mood'
                        options={moodOptions}
                        onChange={(_, data) => setMood(data.value as string)}
                        value={mood}
                    />
                </Form.Field>
                <Form.Field error={!!errorFields[fields.notes]}>
                    <label>Notes</label>
                    <TextArea
                        name={fields.notes}
                        placeholder='Enter notes...'
                        onChange={(e) => handleStringValueChange(e, setNotes)}
                        value={notes}
                    />
                </Form.Field>
                <Button primary type={'submit'} >
                    {id ? 'Update Entry' : 'Add Entry'}
                </Button>
            </Form>
        </Segment>
    );
};