export const validateFormData: (formData: { [key: string]: string | number }, requiredFields: string[], numberFields: string[]) => { [key: string]: string } = (formData, requiredFields, numberFields) => {
    const newErrorFields: { [key: string]: string } = {};

    requiredFields.forEach((f) => {
        if (!formData[f]) {
            newErrorFields[f] = 'Required';
        }
    });

    numberFields.forEach((f) => {
        if (f in formData && isNaN(formData[f] as number)) {
            newErrorFields[f] = 'Value must be a number';
        }
    })

    return newErrorFields;
}