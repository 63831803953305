import React, { useEffect, useState } from 'react';
import { CartesianGrid, LineChart, Line, Tooltip, XAxis, YAxis } from 'recharts';
import { Segment } from 'semantic-ui-react'
import { DailyStats } from 'types/api'
import { getDateString } from 'utils/getDateString';

interface LineGraphProps {
    data: DailyStats[];
}

interface DataPoint {
    name: string;
    daily?: number | null;
    weekly?: number | null;
}

export const LineGraph: React.FunctionComponent<LineGraphProps> = (props) => {
    const { data } = props;
    const [currentData, setCurrentData] = useState<DataPoint[]>([]);
    const [ticks, setTicks] = useState<number[]>([]);

    useEffect(() => {
        if (data.length <= 0) {
            setCurrentData([]);
            return;
        }

        const dataObjectByDate: { [key: string]: number | null | undefined } = data.reduce((a, v) => ({ ...a, [v.date]: v.weightKg }), {});
        const earliest = new Date(data[0].date);
        const latest = new Date(data[data.length - 1].date);
        // Bump hours up by 2 for the latest date to make sure it is ahead of earliest
        // when comparing the same date. If earliest starts in daylight savings time
        // and latest is out of daylight savings, causes the graph to miss the latest date without this.
        latest.setHours(latest.getHours() + 2);

        // Add daily data points
        const newData: DataPoint[] = [];
        while (earliest.getTime() <= latest.getTime()) {
            const dateKey = getDateString(earliest);
            const daily = dataObjectByDate[dateKey];

            newData.push({ name: dateKey, daily });
            earliest.setDate(earliest.getDate() + 1);
        }

        // Calc weekly averages
        let i = 3;
        let sum = 0;
        let numRecorded = 0;
        while (i < newData.length) {
            for (let j = i - 3; j < Math.min(i + 4, newData.length); j++) {
                const val = newData[j].daily;

                if (!!val) {
                    sum += val;
                    numRecorded++;
                }
            }

            newData[i].weekly = Number((sum / numRecorded).toFixed(1));
            sum = 0;
            numRecorded = 0;
            i += 7;
        }

        const values = newData.filter(d => !!d.daily).map(d => d.daily) as number[];
        let ticks: number[] = [];
        if (values.length > 0) {
            // Round max and min to half decimals
            const min = Math.round((Math.min(...values) - 0.5) * 2) / 2;
            const max = Math.round((Math.max(...values) + 0.5) * 2) / 2;
            const numTicks = (max - min) * 2 + 1;
            ticks = Array(numTicks).fill(0).map((_, index) => min + (index / 2));
        }

        setCurrentData(newData);
        setTicks(ticks);
    }, [data]);

    const width = Math.max(window.innerWidth * 0.8, 400);

    return (
        <Segment>
            <LineChart width={width} height={400} data={currentData}>
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="name" hide />
                {ticks.length > 0 && <YAxis type="number" domain={[ticks[0], ticks[ticks.length - 1]]} ticks={ticks} interval={0} />}
                <Tooltip />
                <Line type="monotone" dataKey="daily" stroke="#1E90FF" connectNulls />
                <Line type="monotone" dataKey="weekly" stroke="#FF4500" connectNulls />
            </LineChart>
        </Segment>
    )
};