import React, { useState, useEffect } from "react";
import { Button, Segment, Table } from 'semantic-ui-react'
import { DailyStats } from 'types/api'

interface EntriesTableProps {
    dailyStats: DailyStats[];
    deleteEntry: (id: string) => Promise<void>
}

export const EntriesTable: React.FunctionComponent<EntriesTableProps> = (props) => {
    const { dailyStats, deleteEntry } = props;
    const [reversedData, setReversedData] = useState<DailyStats[]>([]);

    useEffect(() => {
        setReversedData(dailyStats.slice().reverse());
    }, [dailyStats])

    return (
        <Segment inverted>
            <Table unstackable celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>Weight kg</Table.HeaderCell>
                        <Table.HeaderCell>Neck cm</Table.HeaderCell>
                        <Table.HeaderCell>Waist cm</Table.HeaderCell>
                        <Table.HeaderCell>Mood</Table.HeaderCell>
                        <Table.HeaderCell>delete?</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {reversedData.map((ds) => (
                        <Table.Row key={ds.id}>
                            <Table.Cell>{ds.date}</Table.Cell>
                            <Table.Cell>{ds.weightKg}</Table.Cell>
                            <Table.Cell>{ds.neckCm}</Table.Cell>
                            <Table.Cell>{ds.waistCm}</Table.Cell>
                            <Table.Cell>{ds.mood}</Table.Cell>
                            <Table.Cell><Button icon={'close'} color={'red'} inverted onClick={() => deleteEntry(ds.id)} /></Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Segment>
    );
};