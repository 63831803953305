import React, { useState, useEffect } from "react";
import { Amplify } from 'aws-amplify';
import { generateClient } from "aws-amplify/api";
import config from './aws-exports';
import { Header, Segment, Tab } from 'semantic-ui-react'
import { listDailyStats } from "./graphql/queries";
import {
  createDailyStats as createDailyStatsMutation,
  updateDailyStats as updateDailyStatsMutation,
  deleteDailyStats as deleteDailyStatsMutation,
} from "./graphql/mutations";
import { SignOutButton } from "components/SignOutButton";
import { DailyStats, ListDailyStatsQuery, CreateDailyStatsInput, UpdateDailyStatsInput } from "types/api";
import { EntriesTable } from "components/EntriesTable";
import { EntryForm } from "components/EntryForm";
import { LineGraph } from "components/LineGraph";
import { MoodChart } from "components/MoodChart";
import { AuthWrapper } from "components/AuthWrapper";

const styles = {
  rootWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '60px'
  },
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'lato',
    fontWeight: 300,
    fontSize: '50px',
    letterSpacing: '10px',
    marginBottom: '60px',
    minWidth: '400px',
  },
  contentWrapper: {
    width: 'min-content',
    marginLeft: 'auto',
    marginRight: 'auto',
  }
} as { [key: string]: React.CSSProperties };

Amplify.configure(config);

const client = generateClient();

const App = () => {
  const [dailyStats, setDailyStats] = useState<DailyStats[]>([]);

  useEffect(() => {
    updateStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateStats = async () => {
    const stats = await fetchStats();
    stats.sort((a, b) => {
      if (a.date < b.date) {
        return -1;
      } else {
        return 1;
      }
    });
    setDailyStats(stats);
  }

  const fetchStats = async (range?: { from: string, to: string }) => {
    let filter;

    if (range) {
      filter = {
        date: {
          between: [range.from, range.to]
        }
      }
    }

    try {
      let apiData = await (client.graphql({
        query: listDailyStats,
        variables: {
          filter
        },
        authMode: 'userPool'
      }) as Promise<{ data: ListDailyStatsQuery }>);
      let nextToken = apiData.data.listDailyStats?.nextToken;
      let items = (apiData.data.listDailyStats?.items || []).filter(ds => ds !== null) as DailyStats[];

      while (!!nextToken) {
        apiData = await (client.graphql({
          query: listDailyStats,
          variables: {
            filter,
            nextToken
          },
          authMode: 'userPool'
        }) as Promise<{ data: ListDailyStatsQuery }>);
        nextToken = apiData.data.listDailyStats?.nextToken;
        const nextPageItems = (apiData.data.listDailyStats?.items || []).filter(ds => ds !== null) as DailyStats[];
        items = [...items, ...nextPageItems];
      }

      return items;
    } catch {
      return [];
    }
  }

  const createEntry = async (data: CreateDailyStatsInput) => {
    await client.graphql({
      query: createDailyStatsMutation,
      variables: { input: data },
      authMode: 'userPool'
    });
    await updateStats();
  }

  const updateEntry = async (data: UpdateDailyStatsInput) => {
    await client.graphql({
      query: updateDailyStatsMutation,
      variables: { input: data },
      authMode: 'userPool'
    });
    await updateStats();
  }

  const deleteEntry = async (id: string) => {
    const newDailyStats = dailyStats.filter((ds) => ds.id !== id);
    setDailyStats(newDailyStats);
    await client.graphql({
      query: deleteDailyStatsMutation,
      variables: { input: { id } },
    });
  }

  const panes = [
    {
      menuItem: 'Entry Form', render: () =>
        <Tab.Pane inverted>
          <Header as={'h2'}>Add a new entry</Header>
          <EntryForm fetchStats={fetchStats} createEntry={createEntry} updateEntry={updateEntry} />
        </Tab.Pane>
    },
    {
      menuItem: 'Weight Graph', render: () =>
        <Tab.Pane inverted>
          <Header as={'h2'}>Entries</Header>
          <LineGraph data={dailyStats} />
        </Tab.Pane>
    },
    {
      menuItem: 'Mood Chart', render: () =>
        <Tab.Pane inverted>
          <Header as={'h2'}>Entries</Header>
          <MoodChart data={dailyStats} />
        </Tab.Pane>
    },
    {
      menuItem: 'Table', render: () =>
        <Tab.Pane inverted>
          <Header as={'h2'}>Entries</Header>
          <EntriesTable dailyStats={dailyStats} deleteEntry={deleteEntry} />
        </Tab.Pane>
    },
  ]

  return (
    <div style={styles.rootWrapper}>
      <div style={styles.title}>Daily Stats</div>
      <AuthWrapper>
        <Segment inverted style={styles.contentWrapper}>
          <Tab style={{ minWidth: '500px' }} menu={{ inverted: true }} renderActiveOnly={true} panes={panes} />
          <SignOutButton />
        </Segment>
      </AuthWrapper>
    </div >
  );
};

export default App;