import React from 'react';
import './background.scss'

interface BackgroundWrapperProps {
    children?: React.ReactNode
}

export const BackgroundWrapper: React.FunctionComponent<BackgroundWrapperProps> = (props) => {
    return (
        <div id='background-wrapper'>
            <div id='stars' />
            <div id='stars2' />
            <div id='stars3' />
            {props.children}
        </div>
    );
}