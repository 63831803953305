import { DropdownItemProps } from "semantic-ui-react"

export const numberFields: { [key: string]: string } = {
    weightKg: 'weightKg',
    neckCm: 'neckCm',
    waistCm: 'waistCm'
}

export const fields: { [key: string]: string } = {
    date: 'date',
    mood: 'mood',
    notes: 'notes',
    ...numberFields
}

export const moodOptions: DropdownItemProps[] = [
    { value: '', text: 'None' },
    { value: 'very_good', text: '😀 Very good' },
    { value: 'good', text: '🙂 Good' },
    { value: 'average', text: '😐 Average' },
    { value: 'bad', text: '🙁 Bad' },
    { value: 'very_bad', text: '☹️ Very bad' },
]