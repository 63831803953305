import React from "react";
import "@aws-amplify/ui-react/styles.css";
import { Authenticator } from "@aws-amplify/ui-react";
import { SignInHeader } from "./SignInHeader";
import { SignInFooter } from "./SignInFooter";

const components = {
    SignIn: {
        Header: SignInHeader,
        Footer: SignInFooter
    }
};

interface AuthWrapperProps {
    children?: React.ReactNode
}

export const AuthWrapper: React.FunctionComponent<AuthWrapperProps> = (props) => {
    return (
        <Authenticator components={components} hideSignUp={true}>
            {props.children}
        </Authenticator>
    );
}